import { render, staticRenderFns } from "./Bill.vue?vue&type=template&id=1a95acae&scoped=true&"
import script from "./Bill.vue?vue&type=script&lang=js&"
export * from "./Bill.vue?vue&type=script&lang=js&"
import style0 from "./Bill.vue?vue&type=style&index=0&id=1a95acae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a95acae",
  null
  
)

export default component.exports